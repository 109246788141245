module.exports = {
    "防骗指南": "Hướng dẫn chống gian lận", 
    "本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！": "Tất cả tài nguyên trên nền tảng này là tên thật. Anh chị em đều là thành viên VIP hãy tôn trọng nhau. Xin đừng nói những lời tục tĩu. Nếu bị phát hiện bạn sẽ mất quyền hẹn hò . Nói những điều quan trọng 3 lần !", 
    "小姐姐是不会收取任何费用，只需完成平台三次打赏任务获得约炮名额即可享受同城约炮资源，开房花销需自理哦！": "Các em gái sẽ không thu bất cứ khoản phí nào khác.Bạn chỉ cần hoàn thành 3 nhiệm vụ dữ liệu trên nền tảng. để nhận những dịch vụ hẹn hò của nền tảng ! Bạn chỉ cần thanh toán tiền khách sạn .",
    "妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！": "Cô ấy đã xuống dưới.Cần thanh toán .Thanh toán đầy đủ để cô ấy lên lầu .Họ đều là những kẻ nói dối .Vui lòng đến ngay nền tảng để liên hệ CSKH. ",
    "友情提醒：老师，接待员，派单员，都不会私加客户的联系方式，上班时间均为 13.00~01.00 其余时间均不办理业务，若有在下班时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！": "Nhắc nhở tình bạn. thầy .Nhân viên lễ tân ! Thành viên duy nhất .Không tự ý kết bạn khách hàng.Giờ làm việc từ 13:00 đến 01:00 hàng ngày .Nếu ai đó gửi tin nhắn cho bạn Ngoài giờ làm việc, vui lòng không tin để tránh bất kỳ thiệt hại nào về tiền của bạn. Chúng tôi sẽ không chịu trách nhiệm",
    "凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！": "Bất cứ ai yêu cầu tải xuống phần mềm khác đều là giả mạo, vui lòng chụp ảnh màn hình ngay lập tức đến nền tảng để liên hệ với bộ phận chăm sóc khách hàng lễ tân để báo cáo",
    "本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!": "Hệ thống chịu tránh nhiệm tìm kiếm niềm vui cho từng thành viên VIP.Nếu bạn gặp bất kỳ sự cố nào, vui lòng đến nền tảng kịp thời để liên hệ với dịch vụ khách hàng Để báo cáo các bạn sẽ được hệ thống bồi thường.",
    "预约大厅": "sảnh đặt phòng",
    "会员视频": "video thành viên",   
    "骑在身上大屌套弄": "Cưỡi lên người và làm tình ", 
    "选妃中心": "Trung tâm tuyển chọn thê thiếp", 
    "新加入": "mới tham gia", 
    "附近的人": "Những người ở gần",
    "为您发现同城炮友 999+ 位": "Chỉ số tìm bạn của bạn là 999",
    "刷新成功": "Làm mới thành công", 
    "为您发现同城炮友": "Tìm bạn cùng trong thành phố của bạn", 
    "本平台所有资源真实、安全、可靠、全国空降 选取您心仪的她 随时随地 开启约啪~": "Tất cả dữ liệu của nền tảng đều là thật . an toàn. tin cậy. trên toàn quốc chọn cô gái bạn thích và bắt đầu hẹn hò mọi lúc, mọi nơi",
    "联系专属接待客服": "Liên hệ với dịch vụ CSKH độc quyền", 
    "开卡可见": "Nhận VIP để khám phá",
    "寶衡传媒打分": "Kết nối điểm số phương tiện truyền thông",
    "美女颜值": "Giá trị sắc đẹp", 
    "详细地址：已隐藏 联系小蜜开卡可见": "Địa chỉ : Thẻ bị ẩn vui lòng liên hệ CSKH để mở lại thẻ ",
    "联系方式：已隐藏 联系小蜜开卡可见": "phương thức liên lạc: Thẻ bị ẩn vui lòng liên hệ CSKH để mở lại thẻ",
    "基本信息 20岁,身高 175": "Thông tin cơ bản {Age} tuổi, chiều cao {Height}", 
    "所在地区": "Tất cả vị trí：", 
    "服务项目 激活可见": "Hiển thị Mục dịch vụ kích hoạt ", 
    "祝会员们，天天约得美娇娘，夜夜都能当新郎！": "Chúc tất cả thành viên : Hẹn hò với một cô gái xinh đẹp mỗi ngày.Mỗi đêm đều được làm chú rể mới ", 
    "立即约她": "Hẹn hò với cô ấy ngay bây giờ", 
    "请联系专属客服接待": "Vui lòng liên hệ với bộ phận tiếp nhận dịch vụ khách hàng độc quyền.", 
    "寶衡传媒": "Phim 18+", 
    "华人第一福利品牌": "Thương hiệu phúc lợi số 1 cho người Việt.", 
    "为了会员权益，网站只展示": "Vì lợi ích của thành viên. Mạng triển lãm.",
    "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待": "Tài nguyên và cập nhật bất thường để cải thiện ",
    "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧": "Tất cả dữ liệu trên nền tảng đều là thật .an toàn. tin cậy. có mặt trên toàn quốc .hãy chọn 1 cô gái cùng thành phố mới bạn. Hãy nhanh tay gia nhập với chúng tôi.",
    "寶衡资源": "Phim 18+",  
    "昵称": "Tên tài khoản ", 
    "类型": "kiểu",  
    "服务项目: 激活可见": "hạng mục dịch vụ . Hoạt động có thể thấy ", 
    "已认证": "Đã xác minh", 
    "实时配对": "ghép đôi", 
    "约炮心得": "tin nhắn hẹn hò", 
    "服务条款": "Điều khoản dịch vụ", 
    "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。": "Nền tảng có những cô gái xinh đẹp nhất+Hộ tống  Kinh doanh+Hẹn hò cùng thành phố .Để đảm bảo quyền riêng tư của mỗi người dùng.Khách hàng chỉ có thể tham gia bằng cách liên hệ với lễ tân hoặc giới thiệu tên thật của các thành viên cấp cao của nền tảng",
    "有哪些资源?": "có những tài nguyên nào", 
    "网红、模特、空姐、嫩模、大学生，只有您想不到，c": "Người nổi tiếng .người mẫu.tiếp viên hàng không. Gái dưới 18. gái đại học.Không thiếu những gì bạn mong muốn.Không điều gì là không thể trên nền tảng.",
    "服务范围": "Khu vực phục vụ",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游  毒龙 按摩": "hạng mục dịch vụ .Quan hệ tình dục 3 lần bú liếm .massage.",
    "可拍照留念： 私处特写 做爱录屏 口交录屏": "có thể chụp ảnh.Nghi hình cận cảnh sex. làm tình, quan hệ ",
    "可指定制服：护士-女仆-空姐-秘书-女警-猫女": "Đồng phục có thể được chỉ định .Y tá-Người giúp việc-Tiếp viên-Thư ký-Nữ cảnh sát-Miêu nữ",
    "可捆绑轻虐：配合使用各式男女情趣性爱道具": "Có thể chơi trò bịt mắt + trói tay .cùng nhiều đạo cụ làm tình kích dục",
    "北京": "Hà nội",   
    "海防": "Hải phòng",
    "广州": "sài gòn",
    "深圳": "hải dương",
    "南京": "bình dương",
    "长沙": "Nam định",
    "重庆": "Ninh bình",
    "西安": "nha trang",
    "丁先生": "Mr ding ",   
    "通过本站已预约......": "Đặt qua trang web này", 
    "朱先生": "Ông nguyễn minh",  
    "正在服务中......": "Đang trong quá trình phục vụ ", 
    "李先生": "Ông lê nam", 
    "郭先生": "Ông hà văn tiến", 
    "路先生": "Ông đinh thế cử",  
    "郝先生": "Ông lê thế anh", 
    "赵先生": "Ông nguyễn minh tiệp",     
    "祝先生": "Ông nguyễn mạnh tường", 
    "牛先生": "Ông hoàng văn toàn",  
    "张先生": "Ông lê đình triểu",   
    "联系约好时间叫我去她家操她，皮肤还是很紧致": "Liên lạc với tôi nay tối đến nhà em ấy chịch em ấy .Da vẫn mướt mượn",
    "颜值很高，服务很专业，还会热舞...": "Rất ưa nhìn. dịch vụ rất chuyên nghiệp.nóng bỏng",
    "床上满分 可一字马 性格超好 氵超多": "Trên giường đạt điểm tuyệt đối. làm tình nhiều.",
    "朋友介绍的，妞可以年轻漂亮，服务够莞式。": "Bạn bè giới thiệu .Gái trẻ và đẹp.phục vụ nhiệt tình.",
    "今天正好有空闲，正好去验证一下，就约了一下。见面一看，胸不大不小，身材很好，和照片一致。": "Vừa đẹp nay tôi có thời gian.vừa hay đi thẩm các em chút. mới hẹn các em. gặp mặt xem thế nào.Ngực to đẹp , mông công, ráng chuẩn .",
    "进门一看，轻熟女，我喜欢少妇，穿上高跟踩上更 突显身材。简单洗洗开工，口的很舒服，姐口的包裹感十足": "Mới bước vào.Gái trẻ đẹp.Tôi thích gái trẻ .Mang cao gót nhìn tôn ráng.Tắm rửa rồi chén.Thổi kèn rất phê.Tôi cảm thấy rất ưng ý.",
    "很是用心，人也比较魅，比较骚，说话温柔，骚话说起来一套一套，让人兴奋度比较高，能激起男性的性欲": "rất chu đáo.Người hấp dẫn. rất gọi cảm. Nói chuyện rất rễ thương.Nói chuyện tình cảm .Làm tôi rất vui .khơi dậy ham muốn tình dục của tôi.",
    "妹子看上去很小，服务一般，身子有点胖态度还是可以的 喜欢嫩的可以去试试...": "cô gái trông nhỏ bé.Phục vụ bình thường.Body hơi béo thái độ ổn, bạn nào thích dịu dàng thì có thể thử",
    "妹子长相甜美，一颗美人痣最为诱惑身材不错，喜欢旅游，性格温柔开朗": "cô gái trông ngọt ngào.Ráng đẹp lại có nốt ruồi hấp dẫn.Thịch du lịch.Tính tình hiền lành vui vẻ",
    "妹子比较嫩，颜值比较高，就是皮肤有点黑。妹子最近才回上海，简单服务陪浴口做。": "cô gái dịu dàng.Vẻ bề ngoài được.Da hơi ngăm.Cô em này mới hà nội về .",
    "登录": "Đăng nhập ", 
    "请输入用户名": "Vui lòng nhập họ tên ", 
    "请输入密码": "Vui lòng nhập mật khẩu", 
    "忘记密码？": "quên mật khẩu", 
    "没有账户？马上注册": "Không có tài khoản. Đăng ký ngay.", 
    "注册": "Đăng ký ", 
    "请输入用户名(6-12个英文字母或数字)": "Vui lòng nhập tên người dùng (6-12 chữ cái hoặc số )", 
    "请输入登录密码(6-12个英文字母或数字)": "Vui lòng nhập mật khẩu  (6-12 chữ cái hoặc số )", 
    "我已经知晓并同意'开户协议'各项条约": "Tôi đã biết và đồng ý với các điều khoản của 'Thỏa thuận mở tài khoản'",  
    "请输入邀请码": "Vui lòng nhập mã mời", 
    "请勾选下方开户协议": "Vui lòng kiểm tra 'Thỏa thuận đăng ký tài khoản' bên dưới", 
    "预约做单": "đặt lịch hẹn", 
    "距 第": "khoảng cách", 
    "期 截止": "Thời hạn", 
    "第": "tròn", 
    "期": "Cấp", 
    "糖心": "trái tim", 
    "烟花": "pháo hoa", 
    "双份": "gấp đôi", 
    "单份": "một lần", 
    "双": "đôi", 
    "单": "đơn", 
    "本次数据作为直播间打赏主播人气置顶主播排行榜,正式客户完成后可免费开直播间权限": "Lần này dữ liệu được sử dụng làm phần thưởng cho phòng phát sóng trực tiếp.",
    "与主播私下约啪": "Hẹn hò riêng", 
    "任务单": "danh sách nhiệm vụ", 
    "可用余额": "Số dư khả dụng", 
    "元": "điểm",  
    "打赏": "phần thưởng", 
    "当前选号": "lựa chọn hiện tại", 
    "打赏金额": "số tiền thưởng ", 
    "请输入金额": "Vui lòng nhập số tiền",
    "总共打赏": "Tổng số phần thưởng", 
    "合计": "tổng cộng", 
    "清空订单": "xóa đơn hàng", 
    "确认打赏": "xác nhận phần thưởng", 
    "期号": "Round number",
    "正确打赏": "Xác nhận quà tặng",  
    "约炮数据一": "dữ liệu hẹn hò 1", 
    "约炮数据二": "dữ liệu hẹn hò 2",  
    "约炮数据三": "dữ liệu hẹn hò 3",  
    "高级VIP数据": "Dữ liệu VIP cao cấp",  
    "填写收款卡": "Điền vào thẻ thanh toán", 
    "请输入您的收款卡信息": "Vui lòng nhập thông tin thẻ thanh toán của bạn", 
    "请输入真实银行卡号": "Vui lòng nhập số thẻ ngân hàng", 
    "请选择银行": "Vui lòng chọn một ngân hàng", 
    "银行卡号": "số thẻ ngân hàng", 
    "银行名称": "tên ngân hàng", 
    "尊敬的用户,为了保障您的资金安全,请您确认您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款": "Người dùng gần đây.Để đảm bảo an toàn cho tiền của bạn, vui lòng xác nhận tên thật của bạn và đặt mật khẩu rút tiền, nếu tên không khớp với tên tài khoản, bạn sẽ không thể rút tiền",
    "确认提现": "xác nhận rút tiền", 
    "任务记录": "hồ sơ nhiệm vụ", 
    "数据为空": "dữ liệu trống", 
    "打赏积分": "điểm thưởng", 
    "下单时间": "thời gian mua vào", 
    "结算时间": "Thời gian thanh toán", 
    "充值": "Nạp tiền",  
    "提现": "Rút tiền", 
    "我的钱包": "Ví tiền của tôi", 
    "详情": "Chi tiết", 
    "余额(元)": "Số dư", 
    "个人报表": "cá nhân báo cáo", 
    "账户明细": "Chi tiết tài khoản", 
    "打赏记录": "kỷ lục khen thưởng", 
    "个人中心": "trung tâm cá nhân", 
    "信息公告": "thông báo", 
    "在线客服": "dịch vụ trực tuyến", 
    "请完成任务单后进入": "Vui lòng hoàn thành danh sách nhiệm vụ", 
    "请联系客服充值": "Vui lòng liên hệ bộ phận chăm sóc khách hàng để nạp tiền", 
    "请设置收款卡": "Vui lòng thiết lập một thẻ thanh toán ", 
    "功能已禁用": "tính năng bị vô hiệu hóa", 
    "登录/注册": "Đăng ký / Đăng nhập ", 
    "登录可享受更多服务": "Đăng nhập để tận hưởng nhiều dịch vụ hơn", 
    "基本信息": "Thông tin cơ bản", 
    "头像": "hình đại diện",  
    "选择头像": "Chọn hình đại diện", 
    "确定": "Chắc chắn", 
    "真实姓名": "tên thật", 
    "未设置": "không được thiết lập", 
    "性别": "giới tính", 
    "男": "Nam",  
    "女": "Nữ", 
    "未知": "không xác định", 
    "绑定信息": "thông tin liên kết ", 
    "已绑定": "Đã liên kết ", 
    "无": "không có", 
    "系统公告": "Hệ thống thông báo", 
    "c(元)": "số tiền lãi", 
    "盈利计算公式 : 盈利金额 - 任务金额": "Công thức tính lợi nhuận.số tiền lãi .số lượng nhiệm vụ",
    "任务金额": "số lượng nhiệm vụ", 
    "充值金额": "số tiền nạp", 
    "提现金额": "Số tiền rút", 
    "中奖金额": "số tiền thắng", 
    "蜜獾娱乐": "Giải trí", 
    "联系": "Liên hệ", 
    "全天7 * 24小时竭诚为您服务": "Chúng tôi luôn sẵn sàng 24/7 để phục vụ bạn", 
    "收款卡信息": "Thông tin ngân hàng thụ hưởng", 
    "添加收款卡": "Thêm ngân hàng thụ hưởng", 
    "提示:请设置大型商业银行,如需修改,请您联系在线客服": "Mẹo: Hãy thiết lập một ngân hàng. Nếu bạn cần sửa đổi nó, vui lòng liên hệ với dịch vụ khách hàng trực tuyến của chúng tôi",

    "请设置姓名后再绑定银行卡": "Vui lòng thiết lập tên của bạn trước khi liên kết thẻ ngân hàng của bạn", 
    "请设置提现密码后再绑定银行卡": "Vui lòng thiết lập mật khẩu rút tiền trước khi liên kết thẻ ngân hàng của bạn", 
    "修改登录密码": "Thay đổi mật khẩu đăng nhập", 
    "保存": "Lưu lại ", 
    "旧密码": "Mật khẩu cũ",
    "请输入您的旧密码": "Vui lòng nhập mật khẩu cũ của bạn", 
    "新密码": "Mật khẩu mới ", 
    "请输入您的新密码": "Vui lòng nhập mật khẩu mới của bạn", 
    "请填写完整": "Vui lòng điền đầy đủ thông tin", 
    "两次密码输入不一致": "Hai mật khẩu đã nhập không khớp", 
    "修改真实姓名": "Đổi Tên Thật", 
    "姓名": "Tên thật", 
    "请输入真实姓名": "Vui lòng nhập tên thật của bạn", 
    "为了您账户安全,真实姓名需要与绑定银行卡姓名一致": "Để đảm bảo an toàn cho tài khoản của bạn, tên thật của bạn phải khớp với tên trên thẻ ngân hàng được liên kết",

    "请勿重复设置": "Vui lòng tránh cài đặt trùng lặp", 
    "请输入姓名": "xin hãy nhập tên của bạn", 
    "设置资金密码": "Đặt mật khẩu rút tiền", 
    "请输入资金密码": "Vui lòng nhập mật khẩu rút tiền", 
    "请再次输入资金密码": "Vui lòng nhập lại mật khẩu rút tiền", 
    "两次密码不一致": "Mật khẩu không trùng khớp", 
    "性别修改": "sửa đổi giới tính", 
    "设置": "Cài đặt",
    "基本信息设置": "Cài đặt thông tin cơ bản", 
    "登录密码": "Mật khẩu đăng nhập", 
    "资金密码": "Mật khẩu rút tiền ", 
    "退出登录": "Đăng xuất", 
    "提现密码已设置，需要修改请联系客服": "Mật khẩu rút tiền đã được thiết lập. Vui lòng liên hệ với dịch vụ khách hàng để biết bất kỳ sửa đổi nào.",
    "已设置": "đã được thiết lập", 
    "提现中心": " trung tâm rút tiền",
    "提现记录": "Hồ sơ rút tiền", 
    "提现金额 (元)": "Số tiền rút", 
    "到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "Thời gian thanh toán : Thông thường, mất khoảng 5 phút để xử lý giao dịch rút tiền và có thể được thực hiện trong vòng 2 phút",
    "说明": "Thông tin", 
    "余额": "số dư",  
    "马上提现": "Rút tiền ngay bây giờ", 
    "请填写正确的金额": "Vui lòng nhập đúng số tiền",
    "金额": "số dư ", 
    "提交时间": "Thời gian gửi", 
    "审核时间": "Thời gian thử", 
    "没有更多了": "không còn nữa", 
    "播放": "Chơi", 
    "次播放": "Lượt xem", 
    "热门推荐": "Cho bạn",
    "账号下线": "Tài khoản ngoại tuyến",
    "请充值后观看视频": "Vui lòng nạp tiền trước khi xem video", 
    "直播间": "phòng live strem", 
    "官方认证女神": "Cô gái được xác minh chính thức", 
    "已缴纳20000保证金": "Đã đặt cọc 20.000", 
    "地区": "khu vực", 
    "签名": "ký tên ", 
    "获取联系方式": "Phương thức liên lạc", 
    "温馨提示": "vui lòng nhắc nhở",
    "联系数据老师激活权限": "Liên hệ với người quản lý để kích hoạt quyền", 
    "取消": "Hủy bỏ", 
    "标题": "Tiêu đề", 
    "服务项目：做爱3次 可无套内射 深喉口交 漫游 毒龙 按摩": "hạng mục dịch vụ .Quan hệ 3 lần, làm tình bú liếm. massages",
    "首页": "trang chủ ", 
    "预约": "Cuộc hẹn", 
    "我的": "Tôi", 
    "注": "Ghi chú", 
    "已结算": "Đã thanh toán", 
    "结算中": "Đang thanh toán", 
    "待结算": "Bắt đầu thanh toán",
    "金额错误": "số tiền sai", 
    "请选号": "Vui lòng chọn số", 
    "请填写金额": "Vui lòng điền số tiền", 
    "余额不足，请联系客服充值": "Số dư không đủ, vui lòng liên hệ bộ phận chăm sóc khách hàng để nạp tiền", 
    "请联系管理员领取该任务": "Vui lòng liên hệ với quản trị viên để yêu cầu nhiệm vụ này", 
    "开奖成功，期号": "mở thưởng thành công", 
    "玫瑰": "Hoa hồng", 
    "火箭": "Tên lửa ", 
    "添加转数快": "Hệ thống thanh toán nhanh", 
    "填写转数快": "Điền FPS  thanh toán nhanh", 
    "请输入您的转数快信息": "Vui lòng nhập thông tin thanh toán nhanh", 
    "联系电话": "số điện thoại liên hệ", 
    "根据银联风控要求同卡24小时内验证次数不能超过4次，感谢配合": "Theo yêu cầu kiểm soát rủi ro của UnionPay, số lần xác minh cho cùng một thẻ trong vòng 24 giờ không được vượt quá 4 lần, cảm ơn sự hợp tác của bạn",
    "确认": "xác nhận", 
    "已存在提款方式，请勿重复绑定": "Phương thức rút tiền đã tồn tại, vui lòng liên kết lại.",
    "公告": "thông báo ", 
    "東區": "khu đông ", 
    "后生女": "cô gái trẻ", 
    "通過本站已預約......": "Đặt qua trang web này", 
    "正在服務中......": "đang phục vụ", 
    "中西區": "Quận trung tâm và phía tây", 
    "御姐": "Chị gái", 
    "灣仔區": "Hà nội", 
    "火辣": "Rất hót", 
    "南區": "khu vực phía Nam", 
    "油尖旺區": "thành phố nha trang ", 
    "人妻": "vợ", 
    "深水埗區": "hội an", 
    "少婦": "người phụ nữ trẻ", 
    "離島區": "đảo cát bà ", 
    "元朗區": "đà nẵng ", 
    "葵青區": "hồ chí minh ", 
    "沙田區": "quảng ngãi ", 
    "西貢區": "ninh bình ", 
    "北區": "thái bình", 
    "九龍城區": "nam định", 
    "觀塘區": "hà nội",  
    "荃灣區": "móng cái", 
    "屯門區": "tây ninh",  
    "大埔區": "khánh hòa", 
    "顏值很高，服務很專業，還會熱舞...": "Ngoại hình đẹp, phục vụ chuyên nghiệp và vũ đạo nóng bỏng",
    "朋友介紹的，妞可以年輕漂亮，服務夠莞式。": "Được một người bạn giới thiệu, cô gái trẻ và xinh đẹp, phục vụ khá ok",
    "進門一看，輕熟女，我喜歡少婦，穿上高跟踩上更 突顯身材。簡單洗洗開工，口的很舒服，姐口的包裹感十足": "Mới vào đã nhìn thấy , Em gái mới lớn .tôi thích phụ nữ trẻ. cô ấy mang đôi giầy cao gót làm tôn nên dáng của em đó .",
    "床上滿分 可一字馬 性格超好 氵超多": "trên giường đạt điểm tuyệt đối.tính cách ok nhiệt tình",
    "很是用心，人也比較魅，比較騷，說話溫柔，騷話說起來一套一套，讓人興奮度比較高，能激起男性的性慾": "Rất chu đáo, mọi người quyến rũ hơn, quyến rũ hơn, ăn nói nhỏ nhẹ, quyến rũ trong một bộ, khiến mọi người phấn khích hơn và có thể khơi dậy ham muốn tình dục của tôi",
    "聯繫約好時間叫我去她家操她，皮膚還是很緊緻": "cô ấy nhắn tôi đến nhà cô ấy để thịt cô ấy .da vẫn ngon lành .ráng đẹp",
    "未选择":"không được chọn", 
    "请输入联系电话":"nhập số điện thoại liên hệ",  
}